import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AGAMA_ENV, ENV_CONFIG } from 'env'

const Warning = styled.div`
  position: fixed;
  border-radius: 5px;
  padding: 10px 20px;
  background: #ff0e00;
  color: #fff;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9000;
`

const Link = styled.a`
  text-decoration: underline;
  font-weight: bold;
  font-style: italic;
`

export const TestEnvWarning = () => {
  if (AGAMA_ENV === 'prod' || process.env.NODE_ENV === 'development') {
    return null
  }

  return (
    <Warning>
      <Trans id='testEnvWarning'>
        This is a test environment. Please use the{' '}
        <Link href={ENV_CONFIG.prod.appUrl}>Production</Link> environment to log
        your transactions
      </Trans>
    </Warning>
  )
}

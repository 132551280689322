import { extendTheme, ThemeConfig } from '@chakra-ui/react'

import { Card } from 'components/card/card.styles'
import { Button } from 'components/button/button.styles'
import { colors } from './colors'

export const theme = extendTheme({
  colors: {
    ...colors,
    brand: colors.purple,
    primary: colors.purple[400],
    error: colors.red[700],
    success: colors.green[600],
    warning: colors.yellow[600],
    info: colors.blue[600],
  },
  components: {
    Card,
    Button,
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: true,
    cssVarPrefix: 'agama',
  } as ThemeConfig,
})

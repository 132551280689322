import { t } from '@lingui/macro'

import { sendNotification } from 'services/slack'
import { formatCurrency, formatTransactionDate } from 'utils/format'
import { Transaction, User } from './models'

export async function notifyTransaction(transaction: Transaction, user: User) {
  await sendNotification({
    fields: [
      {
        title: t`Date`,
        value: formatTransactionDate(transaction.createdAt),
      },
      { title: t`Seller`, value: `${user.firstName} ${user.lastName}` },
      { title: t`Observations`, value: transaction.observations },
      { title: t`Total`, value: formatCurrency(transaction.total) },
      {
        title: t`Registered total`,
        value: formatCurrency(transaction.registeredTotal),
      },
    ],
  })
}

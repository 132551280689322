export const colors = {
  red: {
    '50': '#FFE7E5',
    '100': '#FFBBB8',
    '200': '#FF908A',
    '300': '#FF655C',
    '400': '#FF392E',
    '500': '#FF0E00',
    '600': '#CC0B00',
    '700': '#990800',
    '800': '#660600',
    '900': '#330300',
  },
  gray: {
    '50': '#F1F2F4',
    '100': '#D7DBE0',
    '200': '#BDC5CB',
    '300': '#A4AEB7',
    '400': '#8A97A3',
    '500': '#70808F',
    '600': '#5A6672',
    '700': '#434D56',
    '800': '#2D3339',
    '900': '#161A1D',
  },
  orange: {
    '50': '#FFF6E5',
    '100': '#FFE6B8',
    '200': '#FFD78A',
    '300': '#FFC75C',
    '400': '#FFB72E',
    '500': '#FFA700',
    '600': '#CC8600',
    '700': '#996400',
    '800': '#664300',
    '900': '#332100',
  },
  yellow: {
    '50': '#FFFAE5',
    '100': '#FFF0B8',
    '200': '#FFE78A',
    '300': '#FFDD5C',
    '400': '#FFD42E',
    '500': '#FFCA00',
    '600': '#CCA200',
    '700': '#997900',
    '800': '#665100',
    '900': '#332800',
  },
  green: {
    '50': '#EBFAEF',
    '100': '#C7F0D1',
    '200': '#A3E6B3',
    '300': '#7FDC96',
    '400': '#5BD278',
    '500': '#37C85A',
    '600': '#2CA048',
    '700': '#217836',
    '800': '#165024',
    '900': '#0B2812',
  },
  cyan: {
    '50': '#E6F8FE',
    '100': '#B9ECFD',
    '200': '#8DDFFC',
    '300': '#60D3FB',
    '400': '#33C6FA',
    '500': '#06BAF9',
    '600': '#0594C7',
    '700': '#046F95',
    '800': '#024A64',
    '900': '#012532',
  },
  purple: {
    '50': '#EBECF9',
    '100': '#C8C9EF',
    '200': '#A4A6E4',
    '300': '#8183DA',
    '400': '#5D61D0',
    '500': '#3A3EC5',
    '600': '#2E319E',
    '700': '#232576',
    '800': '#17194F',
    '900': '#0C0C27',
  },
  pink: {
    '50': '#FDE8EC',
    '100': '#F9BECA',
    '200': '#F594A8',
    '300': '#F16A86',
    '400': '#ED4064',
    '500': '#E91643',
    '600': '#BA1235',
    '700': '#8C0D28',
    '800': '#5D091B',
    '900': '#2F040D',
  },
  teal: {
    '50': '#E5FFFF',
    '100': '#B8FFFE',
    '200': '#8AFFFE',
    '300': '#5CFFFD',
    '400': '#2EFFFD',
    '500': '#00FFFD',
    '600': '#00CCCA',
    '700': '#009998',
    '800': '#006665',
    '900': '#003333',
  },
  blue: {
    '50': '#E6F4FE',
    '100': '#BAE0FC',
    '200': '#8ECBFB',
    '300': '#62B7F9',
    '400': '#36A3F7',
    '500': '#0A8FF5',
    '600': '#0872C4',
    '700': '#065693',
    '800': '#043962',
    '900': '#021D31',
  },
}

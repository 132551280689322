import { t } from '@lingui/macro'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Center, Button, useToast } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'
import { useParams, Navigate, useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { Layout } from 'components/layout'
import { TextInput } from 'components/form'
import { selectProduct } from 'store/products'
import { AppRoute } from 'constants/routing'
import {
  GasStationService,
  ProductUnit,
  UpdatedProductDTO,
} from 'services/gas-station'

type UpdateProductForm = {
  id: string
  name: string
  description: string
  price: number
}

const adaptUpdatedProduct = (
  formData: UpdateProductForm,
): UpdatedProductDTO => ({
  id: formData.id,
  name: formData.name,
  price: {
    unit: ProductUnit.Gallon,
    value: formData.price,
  },
  description: formData.description,
})

const updateProductSchema: yup.SchemaOf<UpdateProductForm> = yup.object({
  id: yup.string().required(),
  name: yup.string().required(t`Field required`),
  description: yup.string().required(),
  price: yup
    .number()
    .required(t`Field required`)
    .typeError(t`Field required`),
})

export const EditProductPage = () => {
  const { productId } = useParams()
  const product = useRecoilValue(selectProduct(productId ?? ''))

  const toast = useToast()
  const navigate = useNavigate()
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<UpdateProductForm>({
    defaultValues: {
      id: product?.id,
      name: product?.name,
      price: product?.price.value,
      description: product?.description,
    },
    resolver: yupResolver(updateProductSchema),
  })

  const onSubmit = handleSubmit(async data => {
    try {
      await GasStationService.UpdateProduct(adaptUpdatedProduct(data))

      toast({
        title: t`Product created successfully`,
        status: 'success',
      })

      navigate(AppRoute.Products)
    } catch (error) {
      toast({
        title: t`Something went wrong`,
        status: 'error',
      })

      console.error(error)
    }
  })

  if (!product) {
    return <Navigate to={AppRoute.Products} />
  }

  return (
    <Layout title={t`Edit product`}>
      <form onSubmit={onSubmit}>
        <TextInput
          name='name'
          label={t`Name`}
          placeholder={t`e.g. GLP`}
          register={register}
          errors={errors}
        />
        <TextInput
          mt={3}
          name='price'
          label={t`Price`}
          leftElement={t`$`}
          type='number'
          step='.0000000001'
          register={register}
          errors={errors}
        />
        <TextInput
          mt={3}
          name='description'
          label={t`Description`}
          register={register}
          errors={errors}
        />
        <Center mt={5}>
          <Button isLoading={isSubmitting} type='submit'>{t`Confirm`}</Button>
        </Center>
      </form>
    </Layout>
  )
}

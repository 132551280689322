import { Button, useColorModeValue } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { Link } from 'react-router-dom'

export const NavItem = ({
  label,
  to,
  icon,
}: {
  to: string
  label: string
  icon: ReactElement
}) => (
  <Button
    variant='ghost'
    as={Link}
    to={to}
    borderRadius='0'
    colorScheme={useColorModeValue('brand', 'black')}
    leftIcon={icon}
    justifyContent='flex-start'
    px={5}
  >
    {label}
  </Button>
)

import { atom, selector, selectorFamily } from 'recoil'

import { GasStationService, Product } from 'services/gas-station'

export const productsState = atom<Product[]>({
  key: 'productsState',
  default: selector({
    key: 'productsState/default',
    get: GasStationService.GetProducts,
  }),
  effects_UNSTABLE: [
    ({ setSelf }) => GasStationService.CreateProductListener(setSelf),
  ],
})

export const selectProductOptions = selector({
  key: 'selectProductOptions',
  get: ({ get }) => {
    const products = get(productsState)

    return products.map(({ name, id }) => ({
      value: id,
      label: name,
    }))
  },
})

export const selectProduct = selectorFamily({
  key: 'selectProduct',
  get:
    (productId: string) =>
    ({ get }) => {
      const products = get(productsState)

      return products.find(product => product.id === productId)
    },
})

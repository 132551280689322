import { ReactNode } from 'react'
import { i18n } from '@lingui/core'
import { I18nProvider as LinguiProvider } from '@lingui/react'
import { es } from 'make-plural/plurals'

import { messages } from 'locales/es-PE/messages'

i18n.loadLocaleData({
  'es-PE': { plurals: es },
})

i18n.load('es-PE', messages)
i18n.activate('es-PE')

export const I18nProvider = ({ children }: { children: ReactNode }) => (
  <LinguiProvider i18n={i18n}>{children}</LinguiProvider>
)

import { useColorModeValue, FlexProps, Flex } from '@chakra-ui/react'

import { Logo } from 'components/logo'
import { AppInfo } from 'components/app-info'
import { NavItems } from './components/nav-items'

import { SideNavContainer } from './sidenav.styles'

export const SideNav = (props: FlexProps) => {
  return (
    <SideNavContainer
      backgroundColor={useColorModeValue('gray.50', 'gray.700')}
      zIndex='banner'
      {...props}
    >
      <Logo
        px={5}
        py={3}
        mb={3}
        borderBottom='2px solid'
        borderColor={useColorModeValue('brand.50', 'gray.600')}
      />
      <Flex h='full' direction='column' justify='space-between'>
        <NavItems />
        <AppInfo p={3} fontWeight='semibold' fontSize='smaller' />
      </Flex>
    </SideNavContainer>
  )
}

import { t } from '@lingui/macro'
import { Heading, Center, Button, useToast } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'

import {
  GasStationService,
  ProductUnit,
  ProductType,
  NewProductDTO,
} from 'services/gas-station'
import { Layout } from 'components/layout'
import { TextInput } from 'components/form'
import { AppRoute } from 'constants/routing'

type NewProductForm = {
  name: string
  description?: string
  price: number
}

const adaptNewProduct = (formData: NewProductForm): NewProductDTO => ({
  name: formData.name,
  price: {
    unit: ProductUnit.Gallon,
    value: formData.price,
  },
  type: ProductType.GasStation,
  description: formData.description,
})

const newProductSchema: yup.SchemaOf<NewProductForm> = yup.object({
  name: yup.string().required(t`Field required`),
  description: yup.string(),
  price: yup
    .number()
    .required(t`Field required`)
    .typeError(t`Field required`),
})

export const NewProductPage = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm({
    resolver: yupResolver(newProductSchema),
  })

  const onSubmit = handleSubmit(async data => {
    try {
      await GasStationService.SaveProduct(adaptNewProduct(data))

      toast({
        title: t`Product created successfully`,
        status: 'success',
      })

      navigate(AppRoute.Products)
    } catch (error) {
      toast({
        title: t`Something went wrong`,
        status: 'error',
      })

      console.error(error)
    }
  })

  return (
    <Layout title={t`Create a new product`}>
      <Heading as='h1' size='md' textAlign='center' mt={10}>
        {t`Create a new product`}
      </Heading>
      <form onSubmit={onSubmit}>
        <TextInput
          name='name'
          label={t`Name`}
          placeholder={t`e.g. GLP`}
          register={register}
          errors={errors}
        />
        <TextInput
          mt={3}
          name='price'
          label={t`Price`}
          leftElement={t`$`}
          type='number'
          step='.0000000001'
          register={register}
          errors={errors}
        />
        <TextInput
          mt={3}
          name='description'
          label={t`Description`}
          register={register}
          errors={errors}
        />
        <Center mt={5}>
          <Button isLoading={isSubmitting} type='submit'>{t`Confirm`}</Button>
        </Center>
      </form>
    </Layout>
  )
}

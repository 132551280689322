import { chakra, Flex } from '@chakra-ui/react'

export const SideNavContainer = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    width: '150px',
  },
})

import { nanoid } from 'nanoid'

import { mul } from 'utils/math'
import { createUser, getCurrentUser } from 'services/firebase'
import {
  getProducts,
  saveProduct,
  saveTransactionItem,
  saveTransaction,
  deleteProduct,
  updateProduct,
  saveUser,
  getTransactions,
  getUsers,
  getTransactionItems,
} from './repository'
import {
  Product,
  NewProductDTO,
  NewTransactionDTO,
  UpdatedProductDTO,
  NewUserDTO,
  Transaction,
  User,
  TransactionItem,
} from './models'
import { createProductListener, createTransactionListener } from './real-time'
import { notifyTransaction } from './notifications'

async function GetUsers(): Promise<User[]> {
  return await getUsers()
}

async function CreateUser(user: NewUserDTO) {
  const now = Date.now()

  const { user: authUser } = await createUser(
    user.email,
    user.temporaryPassword,
  )

  await saveUser({
    id: authUser.uid,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    role: user.role,
    phone: user.phone ?? null,
    createdAt: now,
    updatedAt: now,
  })
}

async function SaveProduct(newProduct: NewProductDTO) {
  const now = Date.now()

  await saveProduct({
    id: nanoid(),
    name: newProduct.name,
    price: newProduct.price,
    type: newProduct.type,
    description: newProduct.description,
    createdAt: now,
    updatedAt: now,
  })
}

async function UpdateProduct(updatedProduct: UpdatedProductDTO) {
  const now = Date.now()

  await updateProduct({
    id: updatedProduct.id,
    name: updatedProduct.name,
    price: updatedProduct.price,
    description: updatedProduct.description,
    updatedAt: now,
  })
}

async function GetProducts(): Promise<Product[]> {
  return await getProducts()
}

async function DeleteProduct(productId: string) {
  return await deleteProduct(productId)
}

async function GetTransactions(): Promise<Transaction[]> {
  return await getTransactions()
}

async function GetTransactionItems(
  transactionId: string,
): Promise<TransactionItem[]> {
  return await getTransactionItems(transactionId)
}

// TODO: Look into batched writes firestore
// https://firebase.google.com/docs/firestore/manage-data/transactions#batched-writes
async function SaveTransaction(newTransaction: NewTransactionDTO) {
  const currentUser = getCurrentUser()

  if (!currentUser) {
    throw new Error('NOT_AUTHENTICATED')
  }

  const now = Date.now()
  const transactionId = nanoid()

  let grandTotal = 0
  let registeredGrandTotal = 0

  for (const item of newTransaction.items) {
    const { productSnapshot, quantity, registeredTotal } = item

    const total = mul(quantity, productSnapshot.price.value)

    grandTotal += total
    registeredGrandTotal += registeredTotal

    await saveTransactionItem({
      id: nanoid(),
      transactionId,
      productSnapshot,
      productType: productSnapshot.type,
      quantity,
      registeredTotal,
      subTotal: total,
      total,
      createdAt: now,
      updatedAt: now,
    })
  }

  const transaction: Transaction = {
    id: transactionId,
    subTotal: grandTotal,
    total: grandTotal,
    registeredTotal: registeredGrandTotal,
    observations: newTransaction.observations,
    createdBy: currentUser.uid,
    createdAt: now,
    updatedAt: now,
  }

  await saveTransaction(transaction)

  return transaction
}

async function SaveTransactionAndNotify(
  newTransaction: NewTransactionDTO,
  user: User,
) {
  return await SaveTransaction(newTransaction).then(transaction =>
    notifyTransaction(transaction, user),
  )
}

export const GasStationService = {
  CreateProductListener: createProductListener,
  CreateTransactionListener: createTransactionListener,
  SaveProduct,
  GetProducts,
  DeleteProduct,
  UpdateProduct,
  GetTransactions,
  GetTransactionItems,
  SaveTransaction,
  SaveTransactionAndNotify,
  CreateUser,
  GetUsers,
}

export enum AppRoute {
  Home = '/',
  SignIn = '/sign-in',
  Products = '/',
  CreateProduct = '/products/create',
  EditProduct = '/products/edit/:productId',
  Transactions = '/transactions',
  TransactionDetails = '/transactions/:transactionId',
  CreateTransaction = '/transactions/register',
  Users = '/users',
  CreateUser = '/users/create',
}

export const DynamicRoute = {
  EditProduct: (productId: string) =>
    AppRoute.EditProduct.replace(':productId', productId),
  TransactionDetails: (transactionId: string) =>
    AppRoute.TransactionDetails.replace(':transactionId', transactionId),
}

import { ReactNode } from 'react'
import { Box, BoxProps, useStyleConfig } from '@chakra-ui/react'

type Props = {
  children: ReactNode
} & BoxProps

export const Card = ({ children, ...rest }: Props) => {
  const styles = useStyleConfig('Card')

  return (
    <Box __css={styles} {...rest}>
      {children}
    </Box>
  )
}

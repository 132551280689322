import { t } from '@lingui/macro'
import { Center, Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import { Layout } from 'components/layout'
import { AppRoute } from 'constants/routing'

export const UsersListPage = () => (
  <Layout title={t`Users`}>
    <Center>
      <Button as={Link} to={AppRoute.CreateUser}>
        {t`Invite a new user`}
      </Button>
    </Center>
  </Layout>
)

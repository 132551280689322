import { AGAMA_ENV } from 'env'
import { APP_VERSION } from 'constants/app-version'

export const AppInfoLogger = () => {
  console.log(
    `AGAMA::GAS_STATION => ${AGAMA_ENV.toUpperCase()} v${APP_VERSION}`,
  )

  return null
}

import { t, Trans } from '@lingui/macro'
import {
  Center,
  Button,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Text,
  Box,
  Flex,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { IoMdAddCircleOutline } from 'react-icons/io'

import { selectTransactionsWithCreator } from 'store/transactions'
import { Layout } from 'components/layout'
import { AppRoute, DynamicRoute } from 'constants/routing'
import { formatCurrency, formatTransactionDate } from 'utils/format'

export const TransactionsListPage = () => {
  const transactions = useRecoilValue(selectTransactionsWithCreator)

  return (
    <Layout title={t`Transactions`} maxWidth='container.xl'>
      <Flex
        mt={5}
        mb={10}
        align='center'
        gridGap={7}
        justify={['space-between', 'space-between', 'flex-start']}
      >
        <Heading as='h1' size='md' textAlign='center'>
          {t`Transactions`}
        </Heading>
        <Center>
          <Button
            as={Link}
            variant='ghost'
            size='md'
            to={AppRoute.CreateTransaction}
            leftIcon={<IoMdAddCircleOutline />}
          >
            {t`Register transaction`}
          </Button>
        </Center>
      </Flex>
      <Box overflowX='scroll'>
        <Table minW='800px'>
          <TableCaption>
            <Trans id='transactionList.caption'>
              <Text
                as='span'
                fontStyle='italic'
                fontWeight='bold'
                textDecoration='underline'
              >
                {t`Auto total`}
              </Text>{' '}
              is automatically calculated by the system and represents the
              transaction total
              <br />
              <Text
                as='span'
                fontStyle='italic'
                fontWeight='bold'
                textDecoration='underline'
              >
                {t`Registered total`}
              </Text>{' '}
              is the transaction total entered by the seller
            </Trans>
          </TableCaption>
          <Thead>
            <Tr>
              <Th>{t`Date`}</Th>
              <Th>{t`Created by`}</Th>
              <Th>{t`Auto total`}</Th>
              <Th>{t`Registered total`}</Th>
              <Th>{t`Actions`}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transactions.map(transaction => (
              <Tr key={transaction.id}>
                <Td>{formatTransactionDate(transaction.createdAt)}</Td>
                <Td>{`${transaction.creator.firstName} ${transaction.creator.lastName}`}</Td>
                <Td isNumeric>{formatCurrency(transaction.total)}</Td>
                <Td isNumeric>{formatCurrency(transaction.registeredTotal)}</Td>
                <Td>
                  <Button
                    as={Link}
                    to={DynamicRoute.TransactionDetails(transaction.id)}
                    variant='link'
                  >
                    {t`View details`}
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Layout>
  )
}

import { Fragment } from 'react'
import { useForm, useFieldArray, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Heading,
  Button,
  Flex,
  Box,
  IconButton,
  Text,
  useToast,
  Divider,
} from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { t } from '@lingui/macro'
import { useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { GasStationService, NewTransactionDTO } from 'services/gas-station'
import { Layout } from 'components/layout'
import { selectProductOptions } from 'store/products'
import { AppRoute } from 'constants/routing'
import { useAuth } from 'auth/use-auth'
import { TextInput } from 'components/form'
import { TransactionForm, TransactionItemModel } from './models'
import { TransactionItem } from './components/transaction-item'
import { TransactionSummary } from './components/transaction-summary'

const adaptTransaction = (formData: TransactionForm): NewTransactionDTO => ({
  items: formData.items.map(item => ({
    productSnapshot: item.snapshot,
    quantity: item.quantity,
    registeredTotal: item.total,
  })),
  observations: formData.observations ?? '',
})

const transactionItemSchema: yup.SchemaOf<TransactionItemModel> = yup.object({
  quantity: yup
    .number()
    .required(t`Field required`)
    .typeError(t`Field required`),
  productId: yup.string().required(t`Field required`),
  total: yup
    .number()
    .required(t`Field required`)
    .typeError(t`Field required`),
  snapshot: yup.mixed() as any,
})

const newTransactionSchema: yup.SchemaOf<TransactionForm> = yup.object({
  items: yup
    .array()
    .of(transactionItemSchema)
    .min(1, t`Min 1 required`),
  observations: yup.string(),
})

export const NewTransactionPage = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const form = useForm<TransactionForm>({
    resolver: yupResolver(newTransactionSchema) as any,
  })
  const { append, remove, fields } = useFieldArray<TransactionForm>({
    name: 'items',
    control: form.control,
  })
  const { currentUser } = useAuth()

  const productOptions = useRecoilValue(selectProductOptions)

  // TODO: Refresh transaction once I start fetching them
  const onSubmit = form.handleSubmit(async data => {
    try {
      await GasStationService.SaveTransactionAndNotify(
        adaptTransaction(data),
        currentUser,
      )

      toast({
        title: t`Transaction registered successfully`,
        status: 'success',
      })

      navigate(AppRoute.Transactions)
    } catch (error) {
      toast({
        title: t`Something went wrong`,
        status: 'error',
      })

      console.error(error)
    }
  })

  return (
    <Layout title={t`Register transaction`} maxW='container.lg'>
      <Heading as='h1' size='md' textAlign='center' mt={10}>
        {t`Register transaction`}
      </Heading>
      <Box mt={5}>
        <FormProvider {...form}>
          <form onSubmit={onSubmit}>
            <Flex direction='column' gridGap={5}>
              {fields.length === 0 && (
                <Text
                  color='gray.300'
                  fontWeight='bold'
                  textAlign='center'
                  fontSize='lg'
                >
                  {t`This transaction has no products. Click on the + button to add a new item.`}
                </Text>
              )}
              {fields.map((field, index) => (
                <Fragment key={field.id}>
                  <TransactionItem
                    prefix={`items.${index}`}
                    onRemove={() => remove(index)}
                    productOptions={productOptions}
                  />
                  {index < fields.length - 1 && <Divider shadow='lg' />}
                </Fragment>
              ))}
            </Flex>
            {fields.length > 0 && (
              <TextInput
                mt={6}
                inputProps={{ as: 'textarea', py: 1, h: '5em' }}
                name='observations'
                register={form.register}
                errors={form.formState.errors}
                label={t`Observations`}
                placeholder={t`Type your observation here`}
              />
            )}
            <Flex justify='space-between' mt={10}>
              <IconButton
                aria-label={t`Add an item to this transaction`}
                icon={<AddIcon />}
                onClick={() => append({ quantity: 1 })}
                borderRadius='full'
                variant='outline'
              >
                {t`Add item`}
              </IconButton>
              <Button
                disabled={!form.formState.isDirty}
                isLoading={form.formState.isSubmitting}
                type='submit'
              >
                {t`Register transaction`}
              </Button>
            </Flex>
          </form>
          <TransactionSummary />
        </FormProvider>
      </Box>
    </Layout>
  )
}

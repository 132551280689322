import { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'
import { Container, ContainerProps } from '@chakra-ui/react'
import Headroom from 'react-headroom'

import { Nav } from 'components/nav'

type Props = {
  title: string
  children: ReactNode
} & ContainerProps

export const LayoutNoSidebar = ({ children, title, ...rest }: Props) => {
  return (
    <>
      <Headroom style={{ zIndex: 5 }}>
        <Nav />
      </Headroom>
      <Container {...rest} as='main' pb={10}>
        <Helmet>
          <title>Agama SAC | {title}</title>
        </Helmet>
        {children}
      </Container>
    </>
  )
}

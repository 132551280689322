import { Flex, Text, Box } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { t } from '@lingui/macro'

import { formatCurrency } from 'utils/format'
import { TransactionItemModel } from 'pages/transactions/new-transaction/models'

export function TransactionSummary() {
  const { watch } = useFormContext()
  const items: TransactionItemModel[] = watch('items') ?? []

  const total = items.reduce((sum, item) => sum + item.total, 0)

  return (
    <Box maxW={[null, '200px']} mt={10} margin={['2.5rem auto 0 auto']}>
      <Flex justifyContent='space-between' alignItems='center'>
        <Text fontSize='xl' fontWeight='bold'>{t`Total`}</Text>
        <Text fontSize='2xl'>{formatCurrency(total)}</Text>
      </Flex>
    </Box>
  )
}

import { Heading, Button, Center, useToast } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'

import { LayoutNoSidebar } from 'components/layout-no-sidebar'
import { TextInput } from 'components/form'
import { Card } from 'components/card'
import { signInWithEmailAndPassword, isKnownAuthError } from 'services/firebase'
import { AppRoute } from 'constants/routing'

type SignInForm = {
  email: string
  password: string
}

const signInSchema: yup.SchemaOf<SignInForm> = yup.object({
  email: yup
    .string()
    .email(t`Email required`)
    .required(t`Email required`),
  password: yup.string().required(t`Password required`),
})

export const SignInPage = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
    setError,
  } = useForm<SignInForm>({
    resolver: yupResolver(signInSchema),
  })

  const onSubmit = handleSubmit(async ({ email, password }) => {
    try {
      await signInWithEmailAndPassword(email, password)

      navigate(AppRoute.Home)
    } catch (error: any) {
      if (isKnownAuthError(error?.code)) {
        setError('email', {})
        setError('password', { message: t`Invalid username or password` })
        return
      }

      toast({
        title: t`Something went wrong`,
        status: 'error',
      })

      console.error(error)
    }
  })

  return (
    <LayoutNoSidebar title={t`Sign In`}>
      <Heading as='h1' size='md' textAlign='center' mt={10}>
        {t`Sign In`}
      </Heading>
      <Card mt={5}>
        <form onSubmit={onSubmit}>
          <TextInput
            name='email'
            label={t`Email`}
            placeholder={t`user@agamasac.com`}
            type='email'
            autoComplete='username'
            register={register}
            errors={errors}
          />
          <TextInput
            mt={3}
            name='password'
            label={t`Password`}
            type='password'
            autoComplete='new-password'
            register={register}
            errors={errors}
          />
          <Center mt={5}>
            <Button isLoading={isSubmitting} type='submit'>{t`Confirm`}</Button>
          </Center>
        </form>
      </Card>
    </LayoutNoSidebar>
  )
}

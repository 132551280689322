import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  DrawerFooter,
  Flex,
  Button,
} from '@chakra-ui/react'
import { BiLogOut } from 'react-icons/bi'
import { t } from '@lingui/macro'

import { signOut } from 'services/firebase'
import { Logo } from 'components/logo'
import { AppInfo } from 'components/app-info'
import { NavItems } from './components/nav-items'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const SideNavMobile = ({ isOpen, onClose }: Props) => (
  <Drawer isOpen={isOpen} onClose={onClose} placement='left'>
    <DrawerOverlay />
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader display='flex' alignItems='center'>
        <Logo />
        <AppInfo ml={3} fontWeight='light' />
      </DrawerHeader>
      <DrawerBody>
        <Flex direction='column'>
          <NavItems />
        </Flex>
      </DrawerBody>
      <DrawerFooter justifyContent='flex-start'>
        <Button onClick={signOut} variant='ghost' leftIcon={<BiLogOut />}>
          {t`Sign out`}
        </Button>
      </DrawerFooter>
    </DrawerContent>
  </Drawer>
)

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormControlProps,
  InputGroup,
  Select,
} from '@chakra-ui/react'
import { path } from 'ramda'

export type SelectOption = {
  label: string
  value: string | number
}

type Props = FormControlProps & {
  name: string
  label: string
  placeholder?: string
  register: any
  errors: Record<string, any>
  options: SelectOption[]
}

export const SelectInput = ({
  label,
  name,
  placeholder,
  register,
  errors,
  options,
  ...rest
}: Props) => {
  const error = path([...name.split('.'), 'message'], errors) as string

  return (
    <FormControl id={name} isInvalid={!!error} {...rest}>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        <Select
          name={name}
          placeholder={placeholder}
          {...(register ? register(name) : null)}
        >
          {options.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </InputGroup>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}

import {
  doc,
  setDoc,
  getDocs,
  collection,
  deleteDoc,
  updateDoc,
  DocumentReference,
  query,
  where,
} from 'firebase/firestore'

import { getDB } from 'services/firebase'
import {
  Product,
  TransactionItem,
  Transaction,
  PartialProduct,
  User,
} from './models'

const db = getDB()

export async function saveUser(user: User) {
  return await setDoc(doc(db, 'users', user.id), user)
}

export async function getUsers() {
  const snapshots = await getDocs(collection(db, 'users'))

  return snapshots.docs.reduce((users, doc) => {
    if (doc.exists()) {
      return [...users, doc.data() as User]
    }

    return users
  }, [] as User[])
}

export async function saveProduct(product: Product) {
  return await setDoc(doc(db, 'products', product.id), product)
}

export async function updateProduct(product: PartialProduct) {
  return await updateDoc<PartialProduct>(
    doc(db, 'products', product.id) as DocumentReference<PartialProduct>,
    product,
  )
}

export async function deleteProduct(productId: string) {
  return await deleteDoc(doc(db, 'products', productId))
}

export async function getProducts(): Promise<Product[]> {
  const snapshot = await getDocs(collection(db, 'products'))

  const products: Product[] = []

  snapshot.forEach(doc => {
    if (doc.exists()) {
      products.push(doc.data() as Product)
    }
  })

  return products
}

export async function saveTransactionItem(transactionItem: TransactionItem) {
  return await setDoc(
    doc(db, 'transactionItems', transactionItem.id),
    transactionItem,
  )
}

export async function saveTransaction(transaction: Transaction) {
  return await setDoc(doc(db, 'transactions', transaction.id), transaction)
}

export async function getTransactions() {
  const snapshot = await getDocs(collection(db, 'transactions'))

  const transactions: Transaction[] = []

  snapshot.forEach(doc => {
    if (doc.exists()) {
      transactions.push(doc.data() as Transaction)
    }
  })

  return transactions
}

export async function getTransactionItems(transactionId: string) {
  const queryResult = await getDocs(
    query(
      collection(db, 'transactionItems'),
      where('transactionId', '==', transactionId),
    ),
  )

  return queryResult.docs.reduce((transactionItems, item) => {
    if (item.exists()) {
      return [...transactionItems, item.data() as TransactionItem]
    }

    return transactionItems
  }, [] as TransactionItem[])
}

export async function getTransactionsForDateRange(
  rangeLower: number,
  rangeUpper: number,
) {
  const queryResult = await getDocs(
    query(
      collection(db, 'transactions'),
      where('createdAt', '>=', rangeLower),
      where('createdAt', '<=', rangeUpper),
    ),
  )

  return queryResult.docs.reduce((transactions, doc) => {
    if (doc.exists()) {
      return [...transactions, doc.data() as Transaction]
    }

    return transactions
  }, [] as Transaction[])
}

import { atom, selector, selectorFamily } from 'recoil'

import {
  GasStationService,
  Transaction,
  TransactionDTO,
  TransactionWithCreatorDTO,
} from 'services/gas-station'
import { selectUser } from 'store/users'
import { DEFAULT_USER } from 'constants/app'

export const transactionsState = atom<Transaction[]>({
  key: 'transactionsState',
  default: selector({
    key: 'transactionsState/default',
    get: GasStationService.GetTransactions,
  }),
  effects_UNSTABLE: [
    ({ setSelf }) => GasStationService.CreateTransactionListener(setSelf),
  ],
})

export const selectTransactions = selector<Transaction[]>({
  key: 'selectTransactions',
  get: ({ get }) =>
    [...get(transactionsState)].sort((a, b) => b.createdAt - a.createdAt),
})

export const selectTransactionsWithCreator = selector<
  TransactionWithCreatorDTO[]
>({
  key: 'selectTransactionsWithCreator',
  get: ({ get }) => {
    const transactions = get(selectTransactions)

    return transactions.map(transaction => ({
      ...transaction,
      creator: get(selectUser(transaction.createdBy)) ?? DEFAULT_USER,
    }))
  },
})

export const selectTransactionDetails = selectorFamily({
  key: 'selectTransactionDetails',
  get:
    (transactionId: string) =>
    async ({ get }) => {
      const transaction = get(selectTransactionsWithCreator).find(
        transaction => transaction.id === transactionId,
      )

      if (!transaction) {
        return undefined
      }

      const aggregatedTransaction: TransactionDTO = {
        ...transaction,
        items: await GasStationService.GetTransactionItems(transactionId),
      }

      return aggregatedTransaction
    },
})

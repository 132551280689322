type AgamaEnv = 'staging' | 'prod'

export const AGAMA_ENV: AgamaEnv =
  (process.env.REACT_APP_AGAMA_ENV as AgamaEnv) ?? 'staging'

type Config = {
  slackWebHook: string
  slackChannel: string
  appUrl: string
}

export const ENV_CONFIG: Record<AgamaEnv, Config> = {
  staging: {
    slackWebHook:
      'https://hooks.slack.com/services/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',
    slackChannel: 'agama-gas-station-dev',
    appUrl: 'https://test.ventas.agamasac.com',
  },
  prod: {
    slackWebHook:
      'https://hooks.slack.com/services/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',
    slackChannel: 'agama-gas-station-prod',
    appUrl: 'https://ventas.agamasac.com',
  },
}

export const CONFIG = ENV_CONFIG[AGAMA_ENV]

import { ComponentStyleConfig } from '@chakra-ui/react'

export const Button: ComponentStyleConfig = {
  baseStyle: {},
  defaultProps: {
    colorScheme: 'brand',
  },
  sizes: {},
  variants: {},
}

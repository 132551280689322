import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useToast,
} from '@chakra-ui/react'
import { useRecoilValueLoadable } from 'recoil'
import { t, Trans } from '@lingui/macro'
import { Link } from 'react-router-dom'
import { useRef, useState } from 'react'

import { GasStationService, Product } from 'services/gas-station'
import { Layout } from 'components/layout'
import { productsState } from 'store/products'
import { AppRoute, DynamicRoute } from 'constants/routing'

export const HomePage = () => {
  const products = useRecoilValueLoadable(productsState)

  const [deletableProduct, setDeletableProduct] = useState<Product>()
  const [isDeleting, setIsDeleting] = useState(false)
  const cancelRef = useRef()
  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure()
  const toast = useToast()

  const onStartDelete = (product: Product) => {
    setDeletableProduct(product)
    onConfirmationOpen()
  }

  const onDeleteProduct = async () => {
    if (deletableProduct) {
      setIsDeleting(true)
      try {
        await GasStationService.DeleteProduct(deletableProduct.id)

        toast({
          title: t`Done!`,
          description: t`The product has been deleted`,
          status: 'success',
        })
      } catch (error) {
        toast({
          title: t`Something went wrong`,
          status: 'error',
        })

        console.error(error)
      } finally {
        onConfirmationClose()
        setIsDeleting(false)
      }
    }
  }

  return (
    <Layout
      title='Inicio'
      display='flex'
      flexDirection='column'
      alignItems='center'
      maxW='container.md'
    >
      <Flex width='full' overflowX='scroll'>
        <Table size='sm' variant='simple' minW='600px'>
          <Thead>
            <Tr>
              <Th>{t`Name`}</Th>
              <Th>{t`Description`}</Th>
              <Th>{t`Price`}</Th>
              <Th>{t`Actions`}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {products.state === 'hasValue' &&
              products.contents.map(product => (
                <Tr key={product.id}>
                  <Td>{product.name}</Td>
                  <Td>{product.description}</Td>
                  <Td>
                    {t`$`} {product.price.value}
                  </Td>
                  <Td>
                    <Flex gridGap={2}>
                      <Button
                        as={Link}
                        to={DynamicRoute.EditProduct(product.id)}
                        size='sm'
                      >
                        {t`Edit`}
                      </Button>
                      <Button
                        onClick={() => onStartDelete(product)}
                        variant='outline'
                        colorScheme='red'
                        size='sm'
                      >
                        {t`Delete`}
                      </Button>
                    </Flex>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Flex>
      <Flex direction='column' mt={10} gridGap={5}>
        <Button as={Link} to={AppRoute.CreateProduct}>
          {t`Create a new product`}
        </Button>
        <Button as={Link} to={AppRoute.CreateTransaction}>
          {t`Register transaction`}
        </Button>
      </Flex>

      <AlertDialog
        isOpen={isConfirmationOpen}
        onClose={onConfirmationClose}
        leastDestructiveRef={cancelRef as any}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>{t`Delete product`}</AlertDialogHeader>
            <AlertDialogBody>
              <Trans>
                Are you sure you want to delete the product{' '}
                <b>{deletableProduct?.name}</b> ?
              </Trans>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef as any} onClick={onConfirmationClose}>
                {t`Cancel`}
              </Button>
              <Button
                isLoading={isDeleting}
                colorScheme='red'
                onClick={onDeleteProduct}
                ml={3}
              >
                {t`Delete`}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Layout>
  )
}

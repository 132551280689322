import { t } from '@lingui/macro'
import {
  Container,
  useColorModeValue,
  Button,
  IconButton,
} from '@chakra-ui/react'
import { BiLogOut } from 'react-icons/bi'
import { GiHamburgerMenu } from 'react-icons/gi'

import { signOut, getCurrentUser } from 'services/firebase'
import { Logo } from 'components/logo'

type Props = {
  onHamburgerClick?: () => void
}

export const Nav = ({ onHamburgerClick }: Props) => {
  const isAuthenticated = !!getCurrentUser()

  return (
    <Container
      as='nav'
      py={4}
      maxW='container.xl'
      background={useColorModeValue('white', 'gray.800')}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
    >
      <Logo visibility={['visible', 'visible', 'hidden']} />
      {isAuthenticated && (
        <Button
          onClick={signOut}
          variant='ghost'
          leftIcon={<BiLogOut />}
          display={['none', 'none', 'flex']}
        >
          {t`Sign out`}
        </Button>
      )}
      {onHamburgerClick && (
        <IconButton
          variant='ghost'
          aria-label='menu'
          icon={<GiHamburgerMenu />}
          onClick={onHamburgerClick}
          display={['flex', 'flex', 'none']}
        />
      )}
    </Container>
  )
}

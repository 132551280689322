import { onSnapshot, collection } from 'firebase/firestore'

import { getDB } from 'services/firebase'
import { Product, Transaction } from './models'

type DataListener<T> = (data: T) => void

const db = getDB()

export const createProductListener = (listener: DataListener<Product[]>) =>
  onSnapshot(collection(db, 'products'), doc => {
    listener(doc.docs.map(d => d.data() as Product))
  })

export const createTransactionListener = (
  listener: DataListener<Transaction[]>,
) =>
  onSnapshot(collection(db, 'transactions'), doc => {
    listener(doc.docs.map(d => d.data() as Transaction))
  })

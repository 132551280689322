import { t } from '@lingui/macro'
import { Heading, Button, Center, useToast } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { RiLockPasswordLine } from 'react-icons/ri'
import { MdOutlineEmail } from 'react-icons/md'
import { FiUser } from 'react-icons/fi'
import { useNavigate } from 'react-router'

import { TextInput, SelectInput } from 'components/form'
import { Layout } from 'components/layout'
import { Role, GasStationService } from 'services/gas-station'
import { AppRoute } from 'constants/routing'
import { APP_EMAIL_DOMAIN } from 'constants/app'
import { CreateUserForm } from './create-user.models'

const PASSWORD_MIN_CHARS = 6

const createUserSchema: yup.SchemaOf<CreateUserForm> = yup.object({
  email: yup
    .string()
    .email(t`Email required`)
    .test(
      'is-valid-domain',
      t({
        id: 'errors.invalidEmailDomain',
        values: { value: APP_EMAIL_DOMAIN },
      }),
      (value?: string) => (value ? value.includes(APP_EMAIL_DOMAIN) : true),
    )
    .required(t`Email required`),
  firstName: yup.string().required(t`Field required`),
  lastName: yup.string().required(t`Field required`),
  role: yup
    .mixed()
    .oneOf([Role.Manager, Role.Seller], t`Field required`)
    .required(t`Field required`),
  temporaryPassword: yup
    .string()
    .required(t`Field required`)
    .min(
      PASSWORD_MIN_CHARS,
      t({ id: 'errors.minRequired', values: { value: PASSWORD_MIN_CHARS } }),
    ),
})

export const CreateUserPage = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(createUserSchema),
  })

  const onSubmit = handleSubmit(async data => {
    try {
      await GasStationService.CreateUser(data)

      toast({
        title: t`User created successfully`,
        status: 'success',
      })

      navigate(AppRoute.Users)
    } catch (error) {
      toast({
        title: t`Something went wrong`,
        status: 'error',
      })

      console.error(error)
    }
  })

  return (
    <Layout title={t`Invite user`}>
      <Heading as='h1' size='md' textAlign='center' my={5}>
        {t`Invite user`}
      </Heading>
      <form onSubmit={onSubmit}>
        <TextInput
          name='email'
          label={t`Email`}
          placeholder={t`user@agamasac.com`}
          type='email'
          register={register}
          errors={errors}
          leftElement={<MdOutlineEmail />}
        />
        <TextInput
          mt={3}
          name='firstName'
          label={t`First name`}
          register={register}
          errors={errors}
          leftElement={<FiUser />}
        />
        <TextInput
          mt={3}
          name='lastName'
          label={t`Last name`}
          register={register}
          errors={errors}
          leftElement={<FiUser />}
        />
        <TextInput
          mt={3}
          name='temporaryPassword'
          label={t`Temporary password`}
          register={register}
          errors={errors}
          leftElement={<RiLockPasswordLine />}
        />
        <SelectInput
          mt={3}
          name='role'
          label={t`Role`}
          placeholder={t`Select a role`}
          options={[
            { label: t`SELLER`, value: Role.Seller },
            { label: t`MANAGER`, value: Role.Manager },
          ]}
          register={register}
          errors={errors}
        />
        <Center mt={10}>
          <Button type='submit' isLoading={isSubmitting}>
            {t`Create user`}
          </Button>
        </Center>
      </form>
    </Layout>
  )
}

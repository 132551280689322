import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

import { useAuth } from 'auth/use-auth'
import { AppRoute } from 'constants/routing'

type Props = {
  children: ReactNode
}

export function AuthGuard({ children }: Props) {
  const { isAuthenticated } = useAuth()

  if (!isAuthenticated) {
    return <Navigate to={AppRoute.SignIn} />
  }

  return <>{children}</>
}

import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { PrivateOutlet } from 'auth/private-outlet'
import { AppRoute } from 'constants/routing'
import { HomePage } from 'pages/home'
import { SignInPage } from 'pages/sign-in'
import { NewProductPage, EditProductPage } from 'pages/products'
import {
  NewTransactionPage,
  TransactionsListPage,
  TransactionDetailsPage,
} from 'pages/transactions'
import { CreateUserPage, UsersListPage } from 'pages/users'

export const AppRouting = () => (
  <BrowserRouter>
    <Routes>
      <Route path={AppRoute.SignIn} element={<SignInPage />} />
      <Route path='/' element={<PrivateOutlet />}>
        <Route path={AppRoute.Users} element={<UsersListPage />} />
        <Route path={AppRoute.CreateUser} element={<CreateUserPage />} />
        <Route
          path={AppRoute.Transactions}
          element={<TransactionsListPage />}
        />
        <Route
          path={AppRoute.CreateTransaction}
          element={<NewTransactionPage />}
        />
        <Route
          path={AppRoute.TransactionDetails}
          element={<TransactionDetailsPage />}
        />
        <Route path={AppRoute.CreateProduct} element={<NewProductPage />} />
        <Route path={AppRoute.EditProduct} element={<EditProductPage />} />
        <Route path={AppRoute.Home} element={<HomePage />} />
      </Route>
    </Routes>
  </BrowserRouter>
)

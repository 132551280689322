import { useEffect } from 'react'
import { Flex, IconButton } from '@chakra-ui/react'
import { DeleteIcon } from '@chakra-ui/icons'
import { useFormContext } from 'react-hook-form'
import { useRecoilValue } from 'recoil'
import { t } from '@lingui/macro'

import { selectProduct } from 'store/products'
import { ProductUnit } from 'services/gas-station'
import { SelectInput, SelectOption, TextInput } from 'components/form'
import { mul } from 'utils/math'

export function TransactionItem({
  prefix,
  onRemove,
  productOptions,
}: {
  prefix: string
  onRemove(): void
  productOptions: SelectOption[]
}) {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useFormContext()

  const productId = watch(`${prefix}.productId`)
  const quantity = watch(`${prefix}.quantity`)
  const product = useRecoilValue(selectProduct(productId))

  useEffect(() => {
    if (productId && product) {
      setValue(`${prefix}.snapshot`, product)
      setValue(`${prefix}.total`, mul(quantity, product.price.value))
      clearErrors(`${prefix}.total`)
    }
  }, [productId, prefix, product, setValue, quantity, clearErrors])

  return (
    <Flex
      gridGap={3}
      alignItems='flex-start'
      direction={['column', 'column', 'row', 'row']}
    >
      <SelectInput
        name={`${prefix}.productId`}
        label={t`Product`}
        placeholder={t`Select a product`}
        options={productOptions}
        register={register}
        errors={errors}
        flex='1 0 30%'
      />
      <Flex width='100%' flex='1 1 auto' gridGap={3}>
        <TextInput
          name={`${prefix}.quantity`}
          type='number'
          label={t`Quantity`}
          step='.001'
          rightAddon={t({
            id: product ? product.price.unit : ProductUnit.Gallon,
          })}
          register={register}
          errors={errors}
          flex='0 1 auto'
        />
        <TextInput
          name={`${prefix}.total`}
          type='number'
          label={t`Total`}
          register={register}
          step='.001'
          errors={errors}
          leftElement={t`$`}
          flex='1 0 40%'
        />
      </Flex>
      <IconButton
        colorScheme='red'
        aria-label={t`Delete item`}
        icon={<DeleteIcon />}
        onClick={onRemove}
        flex='0 1 auto'
        alignSelf={[null, null, 'flex-start']}
        mt={[0, 0, '32px']}
      />
    </Flex>
  )
}

import { atom, selector, selectorFamily } from 'recoil'

import { GasStationService, User } from 'services/gas-station'

// TODO: We might need real time updates here, admins or managers having access to data coming
// from multiple users
export const usersState = atom<User[]>({
  key: 'usersState',
  default: selector({
    key: 'usersState/default',
    get: GasStationService.GetUsers,
  }),
})

export const selectUser = selectorFamily({
  key: 'selectUser',
  get:
    (userId: string) =>
    ({ get }) =>
      get(usersState).find(user => user.id === userId),
})

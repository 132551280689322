import { t } from '@lingui/macro'
import { Flex } from '@chakra-ui/react'
import { RiTempColdFill } from 'react-icons/ri'
import { MdPointOfSale } from 'react-icons/md'

import { AppRoute } from 'constants/routing'
import { NavItem } from './nav-item'

export const NavItems = () => (
  <Flex direction='column'>
    <NavItem
      to={AppRoute.Products}
      label={t`Products`}
      icon={<RiTempColdFill />}
    />
    <NavItem
      to={AppRoute.Transactions}
      label={t`Transactions`}
      icon={<MdPointOfSale />}
    />
  </Flex>
)

import axios from 'axios'

import { CONFIG } from 'env'

type Notification = {
  text?: string
  fields?: {
    title: string
    value: string
  }[]
}

export function sendNotification({ fields, text }: Notification) {
  return axios.post(
    CONFIG.slackWebHook,
    {
      channel: CONFIG.slackChannel,
      text,
      fields,
    },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  )
}

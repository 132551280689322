import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import {
  getAuth,
  signInWithEmailAndPassword as fbSignInEmailPassword,
  updateProfile as fbUpdateProfile,
  User,
  signOut as fbSignOut,
  createUserWithEmailAndPassword,
} from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

import { AGAMA_ENV } from 'env'
import { firebaseConfig as firebaseConfigDev } from './config-dev'
import { firebaseConfig as firebaseConfigProd } from './config-prod'

const isProd = AGAMA_ENV === 'prod'

// Initialize Firebase
const app = initializeApp(isProd ? firebaseConfigProd : firebaseConfigDev)

getAnalytics(app)

// FIREBASE AUTH
const auth = getAuth(app)

export const createUser = createUserWithEmailAndPassword.bind(null, auth)
export const getAuthInstance = () => auth
export const getCurrentUser = () => auth.currentUser
export const signInWithEmailAndPassword = fbSignInEmailPassword.bind(null, auth)
export const updateProfile = () =>
  fbUpdateProfile.bind(null, auth.currentUser as User)
export const signOut = fbSignOut.bind(null, auth)

export const isKnownAuthError = (code: string) =>
  ['auth/user-not-found', 'auth/wrong-password'].includes(code)

// FIREBASE FIRESTORE DB
const db = getFirestore(app)

export const getDB = () => db

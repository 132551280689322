import { useRecoilValue } from 'recoil'
import { ReactNode, useEffect, useState, createContext } from 'react'
import { getAuthInstance } from 'services/firebase'
import { User as AuthUser } from 'firebase/auth'

import { User } from 'services/gas-station'
import { selectUser } from 'store/users'
import { DEFAULT_USER } from 'constants/app'

interface IAuthContext {
  currentUser: User
  isAuthenticated: boolean
}

export const AuthContext = createContext<IAuthContext>({
  currentUser: DEFAULT_USER,
  isAuthenticated: false,
})

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const auth = getAuthInstance()
  const [isReady, setIsReady] = useState(false)
  const [authUser, setAuthUser] = useState<AuthUser | null>(null)
  const currentUser =
    useRecoilValue(selectUser(authUser?.uid ?? '')) ?? DEFAULT_USER

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setAuthUser(user)
      setIsReady(true)
    })

    return unsubscribe
  }, [auth])

  if (!isReady) {
    return null
  }

  return (
    <AuthContext.Provider value={{ currentUser, isAuthenticated: !!authUser }}>
      {children}
    </AuthContext.Provider>
  )
}

import { Text, TextProps } from '@chakra-ui/react'

import { AGAMA_ENV } from 'env'
import { APP_VERSION } from 'constants/app-version'

export const AppInfo = (props: TextProps) => {
  return (
    <Text fontStyle='italic' fontSize='sm' {...props}>
      {AGAMA_ENV} v{APP_VERSION}
    </Text>
  )
}

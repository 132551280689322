import { Role, User } from 'services/gas-station'

export const APP_EMAIL_DOMAIN = 'agamasac.com'

export const DEFAULT_USER: User = {
  id: 'agamasac-default-user',
  email: 'default@agamasac.com',
  firstName: 'AGAMA',
  lastName: 'SAC',
  role: Role.Seller,
  updatedAt: Date.now(),
  createdAt: Date.now(),
}

import { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'
import { Container, ContainerProps, useDisclosure, Box } from '@chakra-ui/react'
import Headroom from 'react-headroom'

import { Nav } from 'components/nav'
import { SideNav, SideNavMobile } from 'components/sidenav'

type Props = {
  title: string
  children: ReactNode
} & ContainerProps

export const Layout = ({ children, title, ...rest }: Props) => {
  const { isOpen, onToggle, onClose } = useDisclosure()

  return (
    <>
      <Headroom style={{ zIndex: 5 }}>
        <Nav onHamburgerClick={onToggle} />
      </Headroom>
      <SideNav display={['none', 'none', 'flex']} />
      <SideNavMobile isOpen={isOpen} onClose={onClose} />
      <Box pl={[null, null, '150px']}>
        <Container {...rest} as='main' pb={10}>
          <Helmet>
            <title>Agama SAC | {title}</title>
          </Helmet>
          {children}
        </Container>
      </Box>
    </>
  )
}

import { Spinner, Container, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

export const LoadingPage = () => (
  <Container centerContent>
    <Spinner
      speed='900ms'
      thickness='3px'
      color='primary'
      size='lg'
      mb={5}
      mt={20}
    />
    <Text fontWeight='bold'>
      <Trans>Loading</Trans>
    </Text>
  </Container>
)

import { i18n } from '@lingui/core'

export const formatCurrency = (value: number) =>
  i18n.number(value, {
    style: 'currency',
    currency: 'PEN',
  })

export const formatTransactionDate = (date: number | string) =>
  i18n.date(new Date(date), {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  })

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { RecoilRoot } from 'recoil'
import { HelmetProvider } from 'react-helmet-async'

import { theme } from 'styles/theme'
import { I18nProvider } from 'providers/i18n-provider'
import { AuthProvider } from 'auth/auth-provider'
import { AppInfoLogger } from 'components/app-info-logger'
import { TestEnvWarning } from 'components/test-env-warning'
import { LoadingPage } from 'pages/loading'
import { AppRouting } from './app-routing'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme} resetCSS>
      <ColorModeScript initialColorMode={theme.config.useSystemColorMode} />
      <I18nProvider>
        <AppInfoLogger />
        <TestEnvWarning />
        <HelmetProvider>
          <RecoilRoot>
            <Suspense fallback={<LoadingPage />}>
              <AuthProvider>
                <AppRouting />
              </AuthProvider>
            </Suspense>
          </RecoilRoot>
        </HelmetProvider>
      </I18nProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { ComponentStyleConfig } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

export const Card: ComponentStyleConfig = {
  baseStyle: props => ({
    borderRadius: '3xl',
    border: '4px solid',
    borderColor: mode('brand.500', 'brand.200')(props),
    boxShadow: 'lg',
    paddingY: 5,
    paddingX: 4,
  }),
  defaultProps: {},
  sizes: {},
  variants: {},
}

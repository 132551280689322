import { t } from '@lingui/macro'
import { useRecoilValue } from 'recoil'
import { useParams, Navigate } from 'react-router'
import {
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Flex,
  Text,
  Box,
} from '@chakra-ui/react'

import { Layout } from 'components/layout'
import { AppRoute } from 'constants/routing'
import { selectTransactionDetails } from 'store/transactions'
import { Heading } from '@chakra-ui/react'
import { formatCurrency, formatTransactionDate } from 'utils/format'

export const TransactionDetailsPage = () => {
  const { transactionId } = useParams()
  const transaction = useRecoilValue(
    selectTransactionDetails(transactionId || ''),
  )

  if (!transaction) {
    return <Navigate to={AppRoute.Transactions} />
  }

  return (
    <Layout title={t`Transaction Details`}>
      <Heading as='h1' size='md' my={5}>
        {t`Transaction #${transaction.createdAt}`}
      </Heading>
      <Box overflowX={['scroll', 'scroll', 'visible']}>
        <Table minW='600px'>
          <Thead>
            <Tr>
              <Th>{t`Product`}</Th>
              <Th>{t`Quantity`}</Th>
              <Th>{t`Price`}</Th>
              <Th>{t`Registered total`}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transaction.items.map(item => (
              <Tr key={item.id}>
                <Td>{item.productSnapshot.name}</Td>
                <Td>
                  {item.quantity} {t({ id: item.productSnapshot.price.unit })}
                </Td>
                <Td isNumeric>
                  {formatCurrency(item.productSnapshot.price.value)}
                </Td>
                <Td isNumeric>{formatCurrency(item.registeredTotal)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Flex direction='column' mt={10} gridGap={2}>
        <SummaryItem
          label={t`Date`}
          value={formatTransactionDate(transaction.createdAt)}
        />
        <SummaryItem
          label={t`Created by`}
          value={`${transaction.creator.firstName} ${transaction.creator.lastName}`}
        />
        {transaction.observations && (
          <SummaryItem
            label={t`Observations`}
            value={transaction.observations}
          />
        )}
        <SummaryItem
          label={t`Auto total`}
          value={formatCurrency(transaction.total)}
        />
        <SummaryItem
          label={t`Registered total`}
          value={formatCurrency(transaction.registeredTotal)}
        />
      </Flex>
    </Layout>
  )
}

function SummaryItem({ label, value }: { label: string; value: string }) {
  return (
    <Flex align='center' gridGap={[5, 5, 3]}>
      <Text w={[null, null, '50%']} fontWeight='bold' textAlign='right'>
        {label}
      </Text>
      <Text w={[null, null, '50%']} fontSize='lg'>
        {value}
      </Text>
    </Flex>
  )
}

import {
  FormControl,
  FormErrorMessage,
  Input,
  FormLabel,
  InputProps,
  FormControlProps,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
} from '@chakra-ui/react'
import { path } from 'ramda'
import { ReactNode } from 'react'

type Props = FormControlProps & {
  name: string
  label: string
  type?: InputProps['type']
  placeholder?: string
  autoComplete?: string
  register: any
  errors: Record<string, any>
  leftElement?: ReactNode
  rightAddon?: string
  step?: string
  inputProps?: InputProps
}

export const TextInput = ({
  label,
  type = 'text',
  name,
  placeholder,
  autoComplete,
  register,
  errors,
  leftElement: leftElementLabel,
  step,
  rightAddon,
  inputProps,
  ...rest
}: Props) => {
  const error = path([...name.split('.'), 'message'], errors) as string

  return (
    <FormControl id={name} isInvalid={!!error} {...rest}>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        {leftElementLabel && (
          <InputLeftElement
            color='gray.300'
            pointerEvents='none'
            children={leftElementLabel}
          />
        )}
        <Input
          {...inputProps}
          placeholder={placeholder}
          name={name}
          type={type}
          autoComplete={autoComplete}
          step={step}
          {...(register ? register(name) : null)}
        />
        {rightAddon && <InputRightAddon children={rightAddon} />}
      </InputGroup>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}

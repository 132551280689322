export enum ProductUnit {
  Gallon = 'GALLON',
  Liter = 'LITER',
}

export enum ProductType {
  GasStation = 'GAS_STATION',
  MiniMarket = 'MINI_MARKET',
}

export interface Product {
  id: string
  name: string
  type: ProductType
  description?: string
  price: {
    value: number
    unit: ProductUnit
  }
  createdAt: number
  updatedAt: number
}

export type PartialProduct = Partial<Product> & {
  id: string
}

export interface TransactionItem {
  id: string
  transactionId: string
  productType: ProductType
  quantity: number
  productSnapshot: Product
  subTotal: number
  total: number
  registeredTotal: number // entered manually
  createdAt: number
  updatedAt: number
}

export interface Transaction {
  id: string
  subTotal: number // auto calculated
  total: number // auto calculated
  registeredTotal: number // entered manually
  observations: string
  createdBy: string // User reference
  createdAt: number
  updatedAt: number
}

export interface TransactionWithCreatorDTO extends Transaction {
  creator: User
}

export interface TransactionDTO extends TransactionWithCreatorDTO {
  items: TransactionItem[]
}

export type NewProductDTO = Pick<
  Product,
  'name' | 'price' | 'type' | 'description'
>

export type UpdatedProductDTO = Pick<
  Product,
  'id' | 'name' | 'price' | 'description'
>

export type NewTransactionDTO = {
  items: {
    quantity: number
    productSnapshot: Product
    registeredTotal: number
  }[]
  observations: string
}

export enum Role {
  Seller = 'SELLER',
  Manager = 'MANAGER',
  Admin = 'ADMIN',
}

export type User = {
  id: string
  email: string
  role: Role
  firstName: string
  lastName: string
  phone?: string | null
  createdAt: number
  updatedAt: number
}

export type NewUserDTO = Pick<
  User,
  'email' | 'role' | 'firstName' | 'lastName' | 'phone'
> & {
  temporaryPassword: string
}

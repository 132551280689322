import { Outlet } from 'react-router-dom'

import { AuthGuard } from 'auth/auth-guard'

export function PrivateOutlet() {
  return (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  )
}

import { Link } from 'react-router-dom'
import { Text, chakra, ChakraProps } from '@chakra-ui/react'

import { AppRoute } from 'constants/routing'

const ChakraLink = chakra(Link)

export const Logo = (props: ChakraProps) => (
  <ChakraLink {...props} to={AppRoute.Home}>
    <Text fontWeight='bold' fontSize='lg' textDecoration='underline'>
      AGAMA SAC
    </Text>
  </ChakraLink>
)
